<template>
  <div class="wrapper">
    <PaywallIframe
      :target-query="getPaywallTargetQuery()"/>
  </div>
</template>

<script>
import { Page } from 'global-components';

export default {
  name: 'coronavirus-paywall-page',
  extends: Page,
  methods: {
    getPaywallTargetQuery() {
      const errorCode = this.$route.query.error_code || this.$store.state.publication.defaultPaywallErrorCode;
      return `?articleUrl=/live/coronavirus&error_code=${errorCode}`;
    },
  }
};
</script>
